/*
 * @Author: zhang_fly 15620636416@163.com
 * @Date: 2023-07-18 09:56:49
 * @LastEditors: zhang_fly 15620636416@163.com
 * @LastEditTime: 2023-08-08 16:36:57
 * @FilePath: /h5/src/api/user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

export  function smscode(data) {
  return request({
    url: '/public/sms',
    method: 'post',
    data
  })
}

export  function login(data) {
  return request({
    url: '/public/login',
    method: 'post',
    data
  })
}

export  function logout() {
  return request({
    url: '/public/logout',
    method: 'post',
  })
}

export  function userinfo() {
  return request({
    url: '/private/user',
    method: 'get',
  })
}

export  function records(parma) {
  return request({
    url: '/private/records',
    method: 'get',
    params:parma
  })
}
// private/manager/employees

export  function staff_list(parma) {
  return request({
    url: 'private/manager/employees',
    method: 'get',
    params:parma
  })
}

export  function staff_add(data) {
  return request({
    url: 'private/manager/employees',
    method: 'post',
    data:data
  })
}

export  function staff_edit(data,id) {
  return request({
    url: 'private/manager/employees/'+id,
    method: 'post',
    data:data
  })
}


export  function staff_detail(id) {
  return request({
    url: 'private/manager/employees/'+id,
    method: 'get',
  })
}
export  function staff_delete(id) {
  return request({
    url: 'private/manager/employees/'+id,
    method: 'delete',
  })
}

export  function shop_info() {
  return request({
    url: '/private/manager/shop',
    method: 'get',
  })
}
export  function shop_edit(data) {
  return request({
    url: 'private/manager/shop',
    method: 'post',
    data:data
  })
}
export  function shop_records(parma) {
  return request({
    url: '/private/manager/records',
    method: 'get',
    params:parma
  })
}
export  function shop_recharge(parma) {
  return request({
    url: '/private/recharge',
    method: 'get',
    params:parma
  })
}
