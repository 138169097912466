import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueWechatTitle from 'vue-wechat-title'
import VuetifyToast from 'vuetify-toast-snackbar'
import VueLoadmore from 'vuejs-loadmore'
import 'vuetify/dist/vuetify.min.css'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(VueWechatTitle)
Vue.use(VueLoadmore)

Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'bottom', // default
  color: 'info', // default
  icon: 'info',
  iconColor: '', // default
  classes: [
      'body-2'
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '', // default
  closeIcon: 'close', // default
  closeColor: '', // default
  slot: [], //default
  shorts: {
      custom: {
          color: 'teal'
      }
  },
  property: '$toast' // default
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
