<template>
  <v-app>
    <v-app-bar color="teal accent-4" dark app>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>chevron_left</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>管理</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-avatar color="primary" size="50">
                <span class="white--text text-h4">{{shopInfo.name?shopInfo.name.slice(0, 1):""}}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
            <v-list-item-title class="text-h6">{{ shopInfo.name }}</v-list-item-title>
            <v-list-item-subtitle class="mt-2"><v-icon small color="">touch_app</v-icon>
                已操作：{{shopInfo.used1}}次</v-list-item-subtitle>
                <v-list-item-subtitle class="mt-2"><v-icon small color="">slow_motion_video</v-icon>
                剩余数：{{shopInfo.mode1Total-shopInfo.used1}}次</v-list-item-subtitle>
            
          </v-list-item-content>
          </v-list-item>
          
        </v-list>
        <v-divider></v-divider>
        <v-list flat>
          <v-list-item-group color="primary">
            <v-list-item to="/staff">
              <v-list-item-icon>
                <v-icon color="primary">manage_accounts</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>员工管理</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item to="/shop_operation_records">
              <v-list-item-icon>
                <v-icon color="primary">format_list_bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>店铺操作记录</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item to="/recharge_records">
              <v-list-item-icon>
                <v-icon color="primary">format_list_bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>充调记录</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item  :to="{ path: '/shop_info', query: { id: shopInfo.id } }"  >
              <v-list-item-icon>
                <v-icon color="primary">info</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>店铺信息</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      
      </v-container>
    </v-main>
    <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="isLoading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
  </v-app>
</template>

<script>
import {shop_info} from "@/api/user.js"
export default {
  name: "Admin",
  data: () => ({
    isLoading: false,
    code: null,
    showCamera: false,
    loadStream: false,
    loading: false,
    shopInfo: {
     
    },
  }),
  created() {
      this.isLoading = true
    shop_info().then((res) => {
    
      console.log(res);
      if (res.code != 200) {
        return false;
      }
      this.shopInfo = res.data;
      this.isLoading = false
    });
  },
  methods: {
   
    signout() {},
    onLoaded() {
      console.log("loaded");
      this.loadStream = true;
    },
  },
};
</script>

