<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>chevron_left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title>详情</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn color="teal accent-4" fab small @click="isEditing = !isEditing">
        <v-icon v-if="isEditing">close</v-icon>
        <v-icon v-else>edit</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-card elevation="4" outlined class="ma-4 pb-8">
        <v-card-title> {{ form.name }} </v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="form.name"
              label="姓名"
              :disabled="!isEditing"
              prepend-inner-icon="dialpad"
              type="text"
              :rules="rules.name"
            />
            <v-text-field
              v-model="form.phone"
              label="手机号"
              :disabled="!isEditing"
              prepend-inner-icon="dialpad"
              type="number"
              :rules="rules.phone"
            />

            <v-radio-group v-model="form.role" :disabled="!isEditing" row>
              <v-radio label="员工" value="1"></v-radio>
              <v-radio label="管理员" value="2"></v-radio>
            </v-radio-group>
            <v-switch
              v-model="form.state"
              :disabled="!isEditing"
              flat
              :label="`状态 ${form.state ? '启用中' : '禁用中'}`"
            ></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="SubStaff"
            v-if="isEditing && pageType == 'detail'"
            >提交修改</v-btn
          >
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="SubStaff"
            v-if="isEditing && pageType == 'add'"
            >新增</v-btn
          >
          <v-btn
            color="error"
            elevation="3"
            v-if="isEditing && pageType == 'detail'"
            rounded
            @click="dialog = true"
            >删除</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="isLoading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
      <v-dialog
        v-model="dialog"
        width="500"
        @click:outside="
          () => {
            this.dialog = false;
          }
        "
      >
        <v-divider></v-divider>
        <v-card>
          <v-card-title color="primary"> 提示 </v-card-title>

          <v-card-text> 确认删除该员工？ </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="gray" text @click="dialog = false"> 取消 </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="deletethisstaff()"> 确认 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<script>
import {
  staff_add,
  staff_edit,
  staff_detail,
  staff_delete,
} from "@/api/user.js";
export default {
  name: "StaffDetail",
  data() {
    return {
      hasSaved: false,
      isEditing: true,
      dialog: false,
      isLoading: false,
      form: {
        id: null,
        name: "",
        phone: null,
        state: true,
        role: "1",
      },
      pageType: "add",
      companies: [],
      shops: [],
      rules: {
        name: [(v) => !!v || "员工姓名不能为空"],
        phone: [
          (v) => !!v || "员工手机号不能为空",
          (v) => /^[1][0-9][0-9]{9}$/.test(v) || "员工手机号格式不正确",
        ],
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    to.query.hasSaved = this.hasSaved;
    next();
  },

  created() {
    const route = this.$route;

    if (route.query.id) {
      this.isEditing = false;
      this.pageType = "detail";
      this.fetchData(route.query.id);
      this.pageType = "detail";
    }
  },
  methods: {
    async SubStaff() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let data = {};
        this.form.state ? (this.form.state = 1) : (this.form.state = 0);
        if (!this.form.id) {
          data = await staff_add(this.form).finally(() => {
            this.isLoading = false;
          });
        } else {
          data = await staff_edit(this.form, this.form.id).finally(() => {
            this.isLoading = false;
          });
        }

        if (data) {
          this.form = data.data;
          this.isEditing = false;
          this.pageType = "detail";
          this.hasSaved = true;
          this.$toast.success(data.message);
          this.$router.go(-1);
        }
      }
    },
    companysFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    async fetchData(id) {
      this.isLoading = true;
      let res = await staff_detail(id);

      this.form = res.data;
      this.form.role = this.form.role.toString();
      this.isLoading = false;
    },
    async deletethisstaff() {
      this.dialog = false;
      this.isLoading = true;
      const res = await staff_delete(this.form.id);
      if (res != null && res.code == 200) {
        this.$toast.success(res.message);
        this.hasSaved = true;
        this.$router.go(-1);
      } else {
        this.$toast.error(res.message);
      }
      this.isLoading = false;
    },
  },
};
</script>
