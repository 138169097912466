<!--
 * @Author: zhang_fly 15620636416@163.com
 * @Date: 2023-07-17 16:28:33
 * @LastEditors: zhang_fly 15620636416@163.com
 * @LastEditTime: 2023-08-07 14:11:05
 * @FilePath: /h5/src/views/OperationRecordsView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE


 使用记录（usage_records）:ID、店铺ID（shop_id）、使用者ID（user_id）、使用模式（mode，模式一为1，模式二为2）。
-->
<template>
  <v-app>
    <v-main>
      <v-app-bar color="teal accent-4" dark app>
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon>chevron_left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>操作记录</v-toolbar-title>
        <v-spacer></v-spacer>        
        累计操作：{{ total }}次      
      </v-app-bar>
      <v-card class="mt-4 mb-4 ml-2 mr-2" flat>
        <vue-loadmore
          :on-refresh="onRefresh"
          :on-loadmore="onLoad"
          :finished="finished"
          load-offset="100"
        >
          <template v-for="(item, index) in datalist">
            <v-divider v-if="index != 0" :key="index"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>
                  设备： {{ item.device.device_no }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon>storefront</v-icon>
                  {{ item.shop.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon>person</v-icon>
                  {{ item.user.name }}
                </v-list-item-subtitle>
                 <v-list-item-subtitle>
                  <v-icon>schedule</v-icon>
                 {{item.updated_at}}
                </v-list-item-subtitle>
              </v-list-item-content>
             
            </v-list-item>
          </template>
        </vue-loadmore>
      </v-card>
    </v-main>
    <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="isLoading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
  </v-app>
</template>

<script>
import Search from "@/components/Search.vue";
import { records } from "@/api/user.js";
export default {
  name: "OperationRecords",
  components: {
    Search,
  },
  data: () => ({
    code: null,
    showCamera: false,
    currentTab: 0,
    showSearch: false,
    finished: false,
    currentPage: 1,
    requestList: [],
    condition: [],
    totalPage: 0,
    total:0,
    datalist: [],
    loadStream: false,
    isLoading: false,
  }),
  created() {
    this.refreshData();
  },
  methods: {
    getData() {
      let params = {
        page: this.currentPage,
        keyword: this.condition.keyword,
      };
      this.isLoading = true;
      records(params)
        .then((res) => {
          this.datalist = this.datalist.concat(res.data.data);
          this.total = res.data.total;
          this.totalPage = res.data.last_page;
          if (res.data.current_page < res.data.last_page) {
            this.currentPage = res.data.current_page + 1;
          } else {
            this.finished = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //清空数据
    clearData() {
      this.datalist = [];
      this.currentPage = 1;
    },
    //重新载入数据
    reloadData() {
      this.clearData();
      this.getData(this.currentTab);
    },

    onRefresh(done) {
      this.reloadData();
      done();
    },
    onLoad(done) {
      this.getData(this.currentTab);
      done();
    },
    refreshData() {
      this.condition = [];
      // this.$refs.search.clear();
      this.reloadData();
    },
    search(data) {
      this.condition = data;
      this.reloadData();
    },
    deleteDialog(id, index) {
      console.log(this.deleteID);
      this.deleteID = id;
      this.deleteIndex = index;
      this.dialog = true;
    },
    cancelDel() {
      this.deleteID = null;
      this.deleteIndex = null;
      this.dialog = false;
    },
    async deletethisstaff() {
      const res = await deletestaff(this.deleteID);
      if (res != null && res.code == 200) {
        this.$toast.success(res.message);
        this.items.splice(this.deleteIndex, 1);
      }
      this.dialog = false;
      console.log(res);
    },
  },
};
</script>

