<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon to="/">
        <v-icon>chevron_left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>启动设备</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-card elevation="4" outlined class="ma-4 pb-8">
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="form.device_id"
                label="设备编码"
                :rules="rules.device_id"
                :disabled="true"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cyan"
              elevation="3"
              rounded
              :loading="waitDialog"
              @click="handleSubmit('1')"
              dark
            >
              <v-icon left> looks_one </v-icon>启动设备</v-btn
            >
            
          </v-card-actions>
        </v-card>

        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h6">
              确认要开机吗?
            </v-card-title>
            <v-card-text>
              请确认：<br />
              1、设备已通电。<br />
              2、上一次的使用已经结束。
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                x-large
                color="primary darken-1"
                text
                @click="dialog = false"
              >
                取消
              </v-btn>
              <v-btn x-large color="primary darken-1" text @click="jog">
                确认
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
      v-model="waitDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
        class="pt-4"
      >
        <v-card-text >
          设备启用大约需要10秒，请耐心等待...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

        <v-overlay :value="loading">
          <v-progress-circular
            indeterminate
            color="primary"
            style="
              position: absolute;
              top: 20%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
            v-if="loading"
            size="70"
            :width="3"
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>
  <script>
import { jog } from "@/api/device";
export default {
  components: {},
  data: () => ({
    waitDialog:false,
    dialog: false,
    code: "",
    disabled: false,
    loading: false,
    flag: 0,
    form: {
      device_id: null,
      times: 1,
      interval: 1,
    },
    modelText: ["", "模式一", "模式二"],
    rules: {
      device_id: [(v) => !!v || "请选择设备"],
    },
  }),
  watch: {
    waitDialog (val) {
        if (!val) return
      
        setTimeout(() => (this.closeWaitDialog()), 10000
        )
      },
    },
  created() {            
    const route = this.$route;
    this.code = route.query.code;
    if (this.code) {
      this.disabled = true;
      this.form.device_id = this.code;
    }
  },

  methods: {
    handleSubmit(times) {
      if (this.$refs.form.validate()) {
        this.flag = 0;
        console.log(this.form);
        this.form.times = times;

        this.dialog = true;
      }
    },
    handleInterval(interval) {
      if (this.$refs.form.validate()) {
        this.flag = 1;
        console.log(this.form);
        this.form.interval = interval;

        this.dialog = true;
      }
    },
    async jog() {
      this.dialog = false;
      this.loading = true;
      let res;
      res = await jog({
        device_no: this.form.device_id,
        mode:"mode"+this.form.times,
      });
      if (res) {
        this.$toast("命令发送成功");
        this.waitDialog=true;
      }
      this.loading = false;
    },
    closeWaitDialog() {
      this.waitDialog = false;
      this.$router.go(-1);
    },
  },
};
</script>
  
  <style>
.input-group--disabled.checkbox .input-group__input {
  color: #000 !important;
}

.theme--light.v-input--is-disabled input {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>