<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg3>
            <v-img
              :src="require('../assets/logo.png')"
              contain
              height="80"
              class="mb-16 mt-n16"
            />
            <v-card class="elevation-12 ml-3 mr-3 font-weight-light rounded-lg">
              <v-toolbar dark color="primary">
                <v-toolbar-title>用户登录</v-toolbar-title>
              </v-toolbar>
              <v-form ref="form" @submit.prevent="handleLogin">
                <v-card-text>
                  <v-row>
                    <v-col cols="9">
                      <v-text-field
                        prepend-icon="phone_iphone"
                        name="phone"
                        label="手机号"
                        :autofocus="true"
                        type="number"
                        v-model.trim="form.phone"
                        :rules="rules.phone"
                        ref="phone"
                        @input="setSendBtnState"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3"  class="d-flex justify-end">
                      <v-btn
                        outlined
                        rounded
                        color="teal"
                        class="mt-4"
                        @click="sendCode"
                        :disabled="!sendBtnState"
                        >{{ sendBtnText }}</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-text-field
                    id="verification_code"
                    prepend-icon="vpn_key"
                    name="verification_code"
                    label="请输入收到的验证码"
                    type="number"
                    v-model.trim="form.verification_code"
                    :rules="rules.code"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="loading"
                    color="primary"
                    elevation="3"
                    rounded
                    type="submit"
                    :disabled="!submitBtnState"
                    >登 录</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { login, smscode } from "@/api/user.js";
export default {
  name: "Login",
  props: {
    source: String,
  },
  data() {
    return {
      form: {
        phone: "",
        verification_code: "",
        verification_key: "",
      },
      is_manager:this.is_manager,
      sendBtnState: false,
      sendBtnText: "验证码",
      submitBtnState: false,
      rules: {
        code: [
          (v) => !!v || "验证码不能为空",
          (v) => /^[0-9]{4}$/.test(v) || "请输入四位数字验证码",
        ],
        phone: [
          (v) => !!v || "手机号不能为空",
          (v) => /^[1][0-9][0-9]{9}$/.test(v) || "手机号格式不正确",
        ],
      },
      loading: false,
    };
  },
  methods: {
    async handleLogin() {
      console.log(this.form);
      if (this.$refs.form.validate()) {
        let data = {};
        this.loading = true;
        data = await login(this.form).finally(() => {
          this.loading = false;
        });
        if (data) {
          let token = data.data.access_token;
          if (token) {
            localStorage.setItem("token", token);
            localStorage.setItem("Ismanager", data.data.is_manager);
            localStorage.setItem("isSuperManager", data.data.is_super_manager);
            
            this.$router.replace("/");
          }
        }
      }
    },
    //发送验证码按钮，验证手机号是否正确，正确则发送验证码，且按钮变为不可点击状态，倒计时
    async sendCode() {
      if (this.$refs.phone.validate()) {
        this.sendBtnState = false;
        this.sendBtnText = "请稍候";
        this.submitBtnState = true;
        let data = {};
        data = await smscode(this.form);
        if (data) {
          this.form.verification_key = data.data.key;
          this.$toast.success(data.message);
          setTimeout(() => {
            this.doLoop(60);
          }, 500);
        } else {
          this.sendBtnState = true;
          this.sendBtnText = "验证码";
        }
      }
    },
    setSendBtnState() {
      if (this.$refs.phone.validate() && this.sendBtnText == "验证码") {
        this.sendBtnState = true;
      } else {
        this.sendBtnState = false;
      }
    },

    doLoop: function (seconds) {
      seconds = seconds ? seconds : 60;
      this.sendBtnText = seconds + "秒";
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.sendBtnText = seconds + "秒";
          --seconds;
        } else {
          this.sendBtnText = "验证码";
          this.sendBtnState = true;
          this.type = "primary";
          clearInterval(countdown);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
