<!--
 * @Author: zhang_fly 15620636416@163.com
 * @Date: 2023-05-23 10:50:55
 * @LastEditors: zhang_fly 15620636416@163.com
 * @LastEditTime: 2023-07-20 10:59:40
 * @FilePath: /vuetify/src/components/SearchChannel.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <v-dialog v-model="show" scrollable max-width="600px" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-light">搜索条件</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-radio-group :value="searchtype" row @change="chuosetype">
            <v-radio label="个人渠道" value="1"></v-radio>
            <v-radio label="企业渠道" value="0"></v-radio>
          </v-radio-group>
           <v-autocomplete
              v-model="branch_id"
              :items="companies"
              :filter="companysFilter"
              item-text="name"
              item-value="id"
              label="公司"
              no-data-text="没有匹配的公司"
              prepend-inner-icon="business"
            ></v-autocomplete>
          <v-form ref="condition" >
            <v-row v-if="searchtype == '1'">
              <v-col cols="12" >
                <v-text-field label="名称" v-model="condition[1].name" prepend-inner-icon="person_outline" clearable />
              </v-col>

              <v-col cols="12">
                <v-text-field label="手机号" v-model="condition[1].phone" prepend-inner-icon="dialpad" clearable />
              </v-col>

            </v-row>
            <v-row  v-else>
              <v-col cols="12">
                <v-text-field label="法人姓名" v-model="condition[0].name" prepend-inner-icon="person_outline" clearable />
              </v-col>

              <v-col cols="12">
                <v-text-field label="手机号" v-model="condition[0].phone" prepend-inner-icon="dialpad" clearable />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="mb-8">
        <v-spacer></v-spacer>
        <v-btn color="gray" elevation="3" rounded @click="clear" class="mr-4"> 清 空 </v-btn>

        <v-btn color="primary" elevation="3" rounded @click="save">
          搜 索
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "Search",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companies:[],
      branch_id:null,
      condition: [
        {
          name: null,
          phone: null,
        },
        {
          name: null,
          phone: null,
        },
      ],
      searchtype: "0",

    };
  },


  mounted() {
   
    this.$forceUpdate();
  },
  activated() {
  },

  computed: {

  },

  methods: {
    chuosetype(e) {
      this.searchtype = e
    },
    close() {
      this.$emit("update:show", false);
    },
    companysFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    clear() {
      this.condition = [{
        name: null,
        phone: null,
      },
      {
        name: null,
        phone: null,
      },];
      this.branch_id =null
    },

    save() {
      if (this.$refs.condition.validate()) {
        this.$emit("update:show", false);
        this.$emit("search", this.condition,this.searchtype,this.branch_id);
      }
    },
  },
};
</script>
