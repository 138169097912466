/*
 * @Author: zhang_fly 15620636416@163.com
 * @Date: 2023-07-17 14:45:58
 * @LastEditors: zhang_fly 15620636416@163.com
 * @LastEditTime: 2023-08-08 15:00:52
 * @FilePath: /h5/src/api/device.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

export function jog(data) {
  return request({
    url: "/private/open",
    method: "post",
    data:data
  });
}

export function jog_interval(device_id,interval) {
  return request({
    url: "/api/private/open/"+device_id+"/"+interval,
    method: "get",
  });
}